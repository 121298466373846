import firstImage from '../image/innovation.jpeg'
import secondImage from "../image/investing.jpeg"
import thirdImage from "../image/employee.jpeg"
import fourthImage from "../image/rethink.jpeg"
import fifthImage from "../image/hybrid.jpeg"
const contentData = {
  name: "John Deo.",
  name2: "MINERVA FINANCE SERVICES",
  name3: "Alexa Johnson",
  role: "MINERVA FINANCE SERVICES",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "http://www.linkedin.com/",
      className: "social_facebook",
    },
    {
      name: "twitter",
      url: "http://www.twitter.com/",
      className: "social_twitter",
    },
    {
      name: "googleplus",
      url: "http://www.googleplus.com",
      className: "social_googleplus",
    },
    {
      name: "pinterest",
      url: "http://www.pinterest.com",
      className: "social_pinterest",
    },
  ],
  aboutme: "About Us",
  aboutdetails:
   `Established with a vision of growth, Minerva Finance Services has consistently expanded its reach, proudly serving retail and corporate customers in more than 170 countries. Our well-regarded documentary credits are globally accepted and can be issued in a variety of ways. With our letter of credit arrangements, we ensure inclusivity by extending our services to all, irrespective of their credit rating, and eliminating the need for extensive underwriting of your company's financials.`,
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Innovation",
      img: firstImage,
      sDetails:
        "By automating network monitoring, it frees up more time for innovation.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Investing",
      img:secondImage,
      sDetails:
        "Opal Investments offers top-notch investment services to its clients in the global market. Traders can access a wide range of asset classes, including stocks, indices, commodities, and various financial instruments, on this platform.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Improve your employees' experience",
      img:thirdImage,
      sDetails:
        "We ensure a versatile, cooperative, and reliable environment for remote employees and those working at the office or branch.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Rethink the organisation",
      img:fourthImage,
      sDetails:
        "COVID-19 has provided answers to pre-existing questions about organizational structures and talent. Remote work efficacy and productivity of agile teams have been proven and are now being widely adopted on a global scale.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Hybrid work webinar",
      img:fifthImage,
      sDetails:
        "Gain insights from financial services professionals on the transition to hybrid work.",
    },
    // {
    //   id: 6,
    //   iconName: "icon-tools",
    //   serviceTitle: "We’re experienced",
    //   sDetails:
    //     "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    // },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Adress: District of Shumen Municipality Shumen Town of Shumen 1 Vasil Levski Str. office 9 Shumen Bulgaria",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +1.900.3456.789",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: support@minervafinanceservices.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: https://www.minervafinanceservices.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default contentData;
