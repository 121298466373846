import React, {Component} from 'react';
import { Link } from 'react-scroll';
import Slider from 'react-slick';

 class TestimonialSlider extends Component {
    render() {
      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        autoplay: false,
        pauseOnHover:true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div>
          <Slider {...settings} className="testimonial_slider">
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/minerva-logo.png')} alt=""/>
                    </div>
                    <h3>Why Minerva Finance Services?</h3>
                    {/* <span>Envato Customer</span> */}
                    <p>At Minerva Finance Services, we present exclusive benefits for investors in search of an exceptional stock market experience. With our platform, you can execute trading strategies swiftly and access real-time financial data, market analyses, and global investment opportunities. Our team of experts is committed to delivering unparalleled customer satisfaction through personalized service and dedicated support.</p>
                    <Link to="contact" className="theme_btn active">
                    Contact Us
                  </Link>
                </div>
               
          </Slider>
        </div>
      );
    }
  }
  export default TestimonialSlider;