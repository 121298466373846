import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Fade } from "react-awesome-reveal";
import Typer from "./Typer";
import { Link } from "react-scroll";

class TypeBanner extends Component {
  render() {
    let contentData = this.props.contentData;
    return (
      <section id="home">
        <Parallax
          bgImage={require("../../image/stock-photo-double-exposure-of-graph-and-rows-of-coins-for-finance-and-business-concept-530884738-transformed.jpeg")}
          strength={0}
          className="banner_area"
        >
          <div className="container">
            <div className="banner_content">
              <Fade direction="up">
                {/* <h5 className="wow fadeInUp animated">HELLO</h5> */}
              </Fade>
              <Fade direction="up" duration={1500}>
                <h2 className="wow fadeInLeft animated">{contentData.name2}</h2>
              </Fade>
              <Fade direction="up" duration={2000}>
                {" "}
                <Typer
                  heading={"More than"}
                  dataText={[
                    "an investment institution..."
                  ]}
                />
              </Fade>
              <div>
                <p>
                Minerva Finance Services is a well-established financial institution with a rich history in the global capital markets. Over the years, we have solidified our position as a trusted player in international financial circles. Our core expertise lies in providing comprehensive stock brokerage services, encompassing specialized leveraged trading and futures transactions.
                </p>
              </div>
              {/* <ul className="list_style social_icon">
                {contentData.socialLinks &&
                  contentData.socialLinks.map((item) => {
                    return (
                      <li key={item.name}>
                        <a href={item.url}>
                          <i className={item.className}></i>
                        </a>
                      </li>
                    );
                  })}
              </ul> */}
              <Link to="service" className="theme_btn">
                   Information
                  </Link>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

export default TypeBanner;
