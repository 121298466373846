import React, { Component } from "react";
import Sectiontitle from "../component/Banner/Sectiontitle";
import { Fade } from "react-awesome-reveal";

class Service extends Component {
  render() {
    let contentData = this.props.contentData;
    var { wClass } = this.props;
    return (
      <section className={`${wClass}`} id="service">
        <div className="container">
          <Sectiontitle
            Title="Our Services"
            TitleP="We provide front, middle, and back-office solutions to adapt to the dynamic investment landscape. Our innovative technology and personalized service enable insightful business decisions."
          />
          <Fade
            direction="up"
            triggerOnce="true"
            cascade
            duration={1000}
            damping={0.4}
          >
            <div className="row">
              {contentData.service &&
                contentData.service.map((item, key) => {
                  console.log(item);
                  return (
                    <div className="col-lg-4 col-sm-6" key={key}>
                      <div
                      style={{backgroundImage: `url(${item.img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}
                        className="work_item wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div  style={{backgroundColor:"rgba(255, 255, 255, 0.7)", minHeight:"300px"}}>
                        <h2 className="t_color">{item.serviceTitle} </h2>
                        <p   style={{color:"#000"}}> {item.sDetails}</p>
                        </div>
                        
                     
                      </div>
                      
                    </div>
                    
                  );
                })}
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}

export default Service;
