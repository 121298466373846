import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
class Footer extends Component {
  render() {
    library.add(faEnvelope);
    library.add(faLocationDot);
    library.add(faPhone);
    let contentData = this.props.contentData;
    const emailAddress = 'support@minervafinanceservices.com'
    // console.log(contentData.contact);
    return (
      <section className="footer-area">
        <div className="container">
          <div className="row">
            <div className="footer-content col-lg-4">
              <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
                <img className="footer-logo" src={require("../image/minerva-logo.png")} alt="" />
              </Link>
              {/* <Fade direction="down" cascade triggerOnce>
              <ul className="list_style">
                {contentData.contact.map((item,i) => {
                  return (
                    <li key={item.icon}>
                        <i key={i} className={item.text}></i>
                    </li>
                  );
                })}
                
              </ul>
            </Fade> */}
            </div>
            <div className="footer-content col-lg-4">
              <p className="footer-text" >Minerva Finance Services offers financial products on this website. Trading CFDs involves high risk; 86% of retail investors lose money. Bonuses are not available to Minerva Finance Services Retail customers due to regulations. Consider your objectives, financial situation, and needs. Minerva Finance LTD does not offer services to residents of certain jurisdictions, including USA, Cuba, Sudan, Syria, Iran, North Korea, Myanmar, India, Germany, France, Russia, United Kingdom, Canada, and Turkey.</p>
            </div>
            <div className="col-lg-4 footer-icons">
              <p>Contact Us</p>
              <p><FontAwesomeIcon className="icons" icon="fa-solid fa-location-dot" />Adress: District of Shumen Municipality Shumen
                Town of Shumen 1 Vasil Levski Str. office 9
                Shumen Bulgaria </p>
              <p><FontAwesomeIcon className="icons" icon="fa-solid fa-phone" />Phone Number: <a href={`tel:+359 877 736 616`}>+359 877 736 616</a></p>
              <p><FontAwesomeIcon className="icons" icon="fa-solid fa-envelope" />E-Mail: <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>

            </div>
          </div>
          {/* <div className="container">
            <div className="row">
              
              <div className="footer-content col-lg-6">
                
              </div>
            </div>
          </div> */}
        </div>
        <div>
          <div className="all-rights-reserved">
            <h6>
              <a href="www.minervafinanceservices.com"> www.minervafinanceservices.com</a> is owned & operated by Minerva Finance Services EOOD
            </h6>
          </div>
          <div className="all-rights-reserved"><br />
            <p>© Minerva Finance Services - All Rights Reserved</p>

          </div>
        </div>
      </section>
    );
  }
}
export default Footer;
